import React from "react";
import { red } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

// Function to generate a block with optional details
export function getBlock(summary, details, detail = false, open = true) {
  if (!detail) {
    return (
      <div>
        <div>{summary}</div>
        {details}
      </div>
    );
  }
  return (
    <details open={open}>
      <summary>{summary}</summary>
      {details}
    </details>
  );
}

// Function to generate a card
export function getCard(title, subheader, avatar, cardcontent, cardactions) {
  return (
    <Box sx={{ m: 1 }}>
      <Card sx={{ maxWidth: 800, spacing: 1 }}>
        <CardHeader title={title} subheader={subheader} avatar={avatar} />
        <CardContent>{cardcontent}</CardContent>
        <CardActions disableSpacing>{cardactions}</CardActions>
      </Card>
    </Box>
  );
}
