import React from 'react';
import LocalizedStrings from 'react-localization';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Grid, List, ListItem } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import TimelineDot from '@mui/lab/TimelineDot';
import { getCard } from './block.jsx';
import { keygen } from './helpers';
import { getYearMonth, getPeriod } from './period.js';

const msInAYear = 365 * 24 * 60 * 60 * 1000;

export default class XP extends React.Component {
    constructor(props) {
        super(props);
        this.titles = new LocalizedStrings({
            en: {
                from: 'from',
                xp: 'Professional Experiences',
                edu: 'Education',
                tech: 'Technologies',
                task: 'Tasks',
                year: 'year',
                years: 'years',
                month: 'month',
                months: 'months',
                and: 'and',
                contractType: 'Contract type'
            },
            fr: {
                from: 'de',
                xp: 'Expériences Professionnelles',
                edu: 'Éducation',
                tech: 'Technologies',
                task: 'Tâches',
                year: 'an',
                years: 'ans',
                month: 'mois',
                and: 'et',
                months: 'mois',
                contractType: 'Type contrat'
            },
            nl: {
                from: 'van',
                xp: 'Werkervaring',
                edu: 'Opleiding',
                tech: 'Technologiëen',
                task: 'Taken',
                year: 'jaar',
                years: 'jaar',
                month: 'maand',
                months: 'maanden',
                and: 'en',
                contractType: 'Soort contract'
            }
        });
    }

    getTechnos(item) {
        return (
            <Box>
                <b>{this.titles.tech}</b>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {item.techno.map((t) => (
                        <ListItem key={keygen()}>{t}</ListItem>
                    ))}
                </List>
            </Box>
        );
    }

    getTasks(item) {
        return (
            <Box>
                <b>{this.titles.task}</b>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {item.local.tasks.map((t) => (
                        <ListItem key={keygen()}>{t}</ListItem>
                    ))}
                </List>
            </Box>
        );
    }

    getXPContractType(item, css) {
        return (
            <span style={css}>
                <span className="icon">&#x1f4dc;</span>
                <span className="contracttype">
                    {item.local.contractType || item.contractType}
                </span>
            </span>
        );
    }

    getXPPeriod(item, css) {
        let [p, d] = getPeriod(item.period.from, item.period.to, this.titles);

        return (
            <span title={d} style={css}>
                <span className="icon">&#x1F4C5;</span>
                {d}&nbsp;<span style={{ fontSize: 'smaller' }}>({p})</span>
            </span>
        );
    }

    getXPCompanyLocation(item, css) {
        return (
            <span style={css}>
                <span className="icon">&#x1f309;</span>
                <a href={`https://www.google.com/maps?q=${item.company.city}`} target="_blank">
                    {item.company.city}
                </a> ({item.company.country})
            </span>
        );
    }

    getXPJobTitle(item, css) {
        return (
            <span style={css} className="title sub">
                <span className="icon">&#x1F4BB;</span>
                {item.local.jobtitle || 'missing .local.jobtitle'}
            </span>
        );
    }

    getXPCompanyName(item, css = {}) {
        return (
            <span style={css}>
                <span className="icon">&#x1F3E2;</span>
                <a href={item.company.url} target="_blank">
                    {item.company.title} ({item.company.type})
                </a>
            </span>
        );
    }

    getExperience(item) {
        let asciiDesc = `${this.titles.task}: ${item.local.tasks.join(', ')}\n\n${this.titles.tech}:${item.techno.join(', ')}`;
        const jobTitle = (
            <Box>
                <Typography variant="body1" color="text.primary">
                    {this.getXPCompanyName(item)}
                    {this.getXPCompanyLocation(item, { float: 'right' })}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    <span style={{ float: 'right', fontSize: 'smaller' }}>
                        {this.getXPContractType(item)} for {this.getXPPeriod(item)}
                    </span>
                    <span>{this.getXPJobTitle(item)}</span>
                </Typography>
            </Box>
        );

        let urls = null;
        if (item.urls !== undefined && item.urls.length > 0) {
            urls = (
                <Typography variant="body2" color="text.secondary">
                    <b>&#x1F517;&nbsp;URLs</b>
                    <List>
                        {item.urls.sort().map((item) => (
                            <ListItem key={keygen()}>
                                <a href={item.url} target="_blank">{item.title}</a>
                            </ListItem>
                        ))}
                    </List>
                </Typography>
            );
        }
        const tasksNtechno = (
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2" color="text.secondary">
                    <Grid container spacing={2}>
                        <Grid xs={6}>{this.getTasks(item)}</Grid>
                        <Grid xs={6}>{this.getTechnos(item)}</Grid>
                    </Grid>
                </Typography>
            </Box>
        );

        const avatar = item.company.logo ? (
            <Avatar alt={item.company.title} src={item.company.logo} />
        ) : (
            <Avatar sx={{ bgcolor: 'cornflowerblue' }} aria-label="job">
                {Array.from(item.company.title)[0]}
            </Avatar>
        );
        return getCard(jobTitle, item.local.description, avatar, tasksNtechno, urls);
    }

    render() {
        return (
            <Box>
                <Typography variant="h4" color="text.secondary">{this.titles.xp}</Typography>
                <Timeline>
                    {this.props.data.map((x) => (
                        x.public &&
                        x.type === 'IT' &&
                        (x.period.to - x.period.from) >= msInAYear / 6 && (
                            <TimelineItem key={keygen()}>
                                <TimelineOppositeContent sx={{ maxWidth: '96px' }} color="textSecondary">
                                    {x.period.from.getFullYear()}&nbsp;-&nbsp;{x.period.to.getFullYear()}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>{this.getExperience(x)}</TimelineContent>
                            </TimelineItem>
                        )
                    ))}
                </Timeline>
            </Box>
        );
    }
}
