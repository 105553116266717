export var references = [
  /*  {
      title: 'GAS',
      desc: 'Geographical Animation System SaaS',
      url:
          'https://www.youtube.com/watch?v=Q7QVsvwXXg8'
    },*/
  {
    title: "npm",
    desc: "Node Packages",
    url: "https://www.npmjs.com/~ruijs",
    icon: {
      svg: {
        props: { width: "24px", height: "14px", viewBox: "0 0 14 14" },
        children: [
          { type: "path", props: { fill: "#CB3837", d: "M0 0v6h5v1h4V6h9V0" } },
          {
            type: "path",
            props: {
              fill: "#FFF",
              d:
                "M1 1v4h2V2h1v3h1V1h1v5h2V2h1v2H8v1h2V1h1v4h2V2h1v3h1V2h1v3h1V1",
            },
          },
        ],
      },
    },
  },
  {
    title: "GitHub",
    url: "https://github.com/jeanphilipperuijs",
    desc: "Some Open-source Projects",
    icon: "https://github.com/favicon.ico",
  },
  {
    title: "MPAT Project - H2020",
    url: "http://mpat.eu",
    desc: "Multi Platform Application Toolkit for HbbTV",
    icon: "http://mpat.eu/wp-content/uploads/2016/02/mpat_logo.png",
  },
  {
    title: "Télécom Paristech",
    desc: "GPAC",
    url: "http://www.tsi.telecom-paristech.fr/mm/themes/equipe-gpac/",
    icon:
      "https://alternance.imt.fr/wp-content/uploads/2017/01/IMT_logo_RVB.png",
  },
];
