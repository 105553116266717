var keywords = {
  // mouse: String.fromCodePoint(0x1F5B1),
  dev: [String.fromCodePoint(0x2328), "Developement"],
  gis: [String.fromCodePoint(0x1f310), "GIS"],
  dataviz: ["DataViz"],
  admin: [String.fromCodePoint(0x1f5a5), "Administration"],
  os: "Power User",
  db: [String.fromCodePoint(0x1f4be), "DataBase"],
  audio: [String.fromCodePoint(0x1f39b), "Audio"],
  video: [String.fromCodePoint(0x1f3fc), "Video"],
  cgi: [String.fromCodePoint(0x1f5b2), "Computer-generated imagery"],
};

//export let devlang = ['Java', 'Python', 'JavaScript', 'C', 'C++'];

export var chronology = [
  {
    title: "P2000 Basic",
    since: 1983,
    type: keywords.dev,
    desc: "More then a hello world",
  },
  { title: "MS-DOS", since: 1985, type: keywords.admin },
  { hide: true, title: "Windows", since: 1987, type: keywords.admin },
  { hide: true, title: "GeoWorks", since: 1990, type: keywords.admin },
  { hide: true, title: "PCM", since: 1992, type: keywords.whocares },
  { hide: true, title: "OS/2", since: 1994, type: keywords.whocares },
  { title: "Linux", since: 1996, type: keywords.admin },
  { title: "HTML/CSS", since: 1996, type: keywords.dev },
  { hide: true, title: "CSS", since: 1996, type: keywords.dev },
  { title: "JavaScript", since: 1996, type: keywords.dev },
  { title: "PHP", since: 1999, type: keywords.dev },
  { title: "Novell NetWare", since: 2000, type: keywords.admin },
  { title: "MySQL", since: 2000, type: [keywords.admin, keywords.db] },
  {
    title: "Java",
    since: 2001,
    type: [
      keywords.dev,
      keywords.gis,
      keywords.audio,
      keywords.cgi,
      keywords.admin,
      keywords.dataviz,
    ],
  },
  { title: "Java Server Pages / Servlets", since: 2001, type: keywords.dev },
  { hide: true, title: "Tomcat", since: 2001, type: keywords.dev },
  { title: "Solaris", since: 2003, type: keywords.admin },
  { hide: true, title: "DNS Bind", since: 2003, type: keywords.admin },
  { title: "Python", since: 2006, type: keywords.dev },
  { title: "Eclipse", since: 2006, type: keywords.dev },
  { hide: true, title: "Visual Studio", since: 2006, type: keywords.dev },
  {
    hide: true,
    title: "Android",
    since: 2010,
    url: "https://play.google.com/store/apps/developer?id=JPh",
    type: keywords.dev,
  },
  {
    title: "Geographical Animation System",
    since: 2013,
    url:
      "http://www.youtube.com/watch?v=fQB3FEfrSHo&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8",
    type: [keywords.gis, keywords.dev, keywords.db, keywords.cgi],
  },
  { hide: true, title: "Windows Mobile", since: 2006, type: keywords.dev },
  { hide: true, title: "WGS84", since: 2006, type: keywords.gis },
  { hide: true, title: "NMEA", since: 2006, type: keywords.gis },
  { hide: true, title: "C#", since: 2007, type: keywords.dev },
  { hide: true, title: "Embedded", since: 2009, type: keywords.whocares },
  { title: "Arduino", since: 2009, type: keywords.dev },
  { title: "RasberryPi", since: 2009, type: keywords.admin },
  {
    hide: true,
    title: "Oracle",
    since: 2010,
    type: [keywords.admin, keywords.db],
  },
  {
    hide: true,
    title: "SharePoint",
    since: 2011,
    type: [keywords.dev, keywords.admin],
  },
  {
    hide: true,
    title: "OpenLDAP",
    since: 2012,
    type: [keywords.admin, keywords.dev],
  },
  { hide: true, title: "FreeRadius", since: 2012, type: keywords.admin },
  { hide: true, title: "Processing", since: 2013, type: keywords.dev },
  { hide: true, title: "RDS", since: 2013, type: keywords.gis },
  {
    title: "D3JS",
    since: 2013,
    type: [keywords.dev, keywords.video, keywords.gis],
  },
  { title: "Elastic", since: 2019, type: keywords.admin },
  { title: "Kubernetes", since: 2019, type: keywords.admin },
  { title: "OpenShift", since: 2019, type: keywords.admin },
  { title: "Go", since: 2022, type: keywords.dev, },
];
