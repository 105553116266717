import React from "react";
import LocalizedStrings from "react-localization";
import { keygen } from "./helpers";

export default class TechnoChron extends React.Component {
  constructor(props) {
    super(props);
    this.titles = new LocalizedStrings({
      en: {
        tech: "Technology-Chronology",
        since: "Since",
      },
      fr: {
        tech: "Chronologie des téchnologies",
        since: "Depuis",
      },
      nl: {
        tech: "Technologie-chronologie",
        since: "Sinds",
      },
    });
  }

  getItem(item) {
    let types = item.type;
    if (typeof item.type === "object") {
      types = (
        <details>
          <summary>...</summary>
          {item.type.map((t) => (
            <li key={keygen()}>{t}</li>
          ))}
        </details>
      );
    }
    return (
      <tr key={keygen()} style={{ columnCount: 3, fontSize: "1em" }}>
        <td style={{ fontStyle: "light", width: "72px" }}>{item.since}</td>
        <td
          style={{ cursor: item.url ? "pointer" : "arrow", padding: "10px" }}
          onClick={() => window.open(item.url)}
        >
          {item.title}
        </td>
        <td>
          {typeof item.type === "string" ? (
            item.type
          ) : (
            <ul>
              {item.type.map((cat) => {
                return <li>{cat}</li>;
              })}
            </ul>
          )}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div id="technochron" className="blocked">
        <h2 className="title sup">{this.titles.tech}</h2>
        <div
          style={{
            breakInside: "avoid-column",
            margin: "auto",
            width: "90%",
            minWidth: "686px",
          }}
        >
          {this.props.data.map((item) => {
            if (!item.hide) return this.getItem(item);
          })}
        </div>
      </div>
    );
  }
}
