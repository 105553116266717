/*
import { Oscillator }
from './audio/oscille.js';
let osc = new Oscillator();
osc.pHun();
*/
//window.location='https://

import { createRoot } from "react-dom/client";
import Divider from "@mui/material/Divider";
import React from "react";
import Edu from "./edu.jsx";
import XP from "./xp.jsx";
import Contact from "./contact.jsx";
import TechnoChron from "./technochron.jsx";
import { data_xp } from "./data/data_xp.js";
import { data_edu } from "./data/data_edu.js";
import { perso, urls, i18n } from "./data/data_contact.js";
import { references } from "./data/data_ref.js";
import { chronology } from "./data/data_skills.js";
import { magic, vape } from "./koekie/monster.js";
magic();
//vape();
const pixelWidth = 910;
let cnt = document.createElement("div");
document.body.appendChild(cnt);

let width = window.innerWidth * 0.8;
let left = window.innerWidth * 0.05;
//document.cookie=`debug=true; expires=${new Date().toISOString()}; path=/;`;

const rootElement = document.getElementById("root");
createRoot(rootElement).render(
  // <div style={{
  //     maxWidth: `${pixelWidth}px`,
  //     width: `${width}px`, padding: '10px', alignContent: 'center',
  //     position: 'relative', left: `${left}px`
  // }}>
  // <div style={{ minWidth: 800, maxWidth: 1024 }}>
  <div>  <Contact edu={data_edu} data={{ perso, urls, references, i18n }} open={false} />
    <Divider />
    <XP data={data_xp} open={true} />
    <Divider />
    <Edu data={data_edu} open={true} />
    {/* <Divider />
    <TechnoChron data={chronology} open={true} />
    <Divider /> */}
  </div>
  ,
  cnt
);
