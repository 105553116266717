import React from "react";
import LocalizedStrings from "react-localization";
import { keygen } from "./helpers";
import { getPeriod } from "./period.js";
import { getBlock, getCard } from "./block.jsx";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Divider } from "@mui/material";

export default class Edu extends React.Component {
  constructor() {
    super();
    this.titles = new LocalizedStrings({
      en: {
        edu: "Education",
        fac: "Faculty",
        major: "Major",
        subjects: "Subjects",
      },
      fr: {
        edu: "Éducation",
        fac: "Faculté",
        major: "Major",
        subjects: "Sujects",
      },
      nl: {
        edu: "Opleiding",
        fac: "Faculteit",
        major: "Major",
        subjects: "Onderwerpen",
      },
    });
  }
  getSubjects(item) {
    return (
      <div>
        <div className="sub title">{this.titles.subjects}</div>
        <ul className="technos colled">
          {item.school.i18n.subjects.map((t) => (
            <li key={keygen()} className="techno">
              {t}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  getCertification(item) {
    let cardcontent = item.obtained ? item.degree : null;

    let cardactions = (
      <div>
        <img src={item.badgeUrl} width={64} />
        <div>
          {item.o} certified: {item.degree}
        </div>
      </div>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }} aria-label="job">
        {Array.from(item.degree)[0]}
      </Avatar>
    );

    let v = getCard(item.degree, item.o, avatar, cardcontent, cardactions);

    return v;
  }

  getEducation(item) {
    let s = (
      <div title="desc" style={{ marginTop: "2em" }} className="company">
        <h3>
          {item.school.obtained ? (
            <li style={{ fontWeight: "bold" }}>{item.school.degree}</li>
          ) : null}
        </h3>
        <h4 className="sub title">
          <a
            href={`https://www.google.com/maps?q=${item.school.city}`}
            target="_blank"
          >
            {item.school.o}
          </a>{" "}
          - <span style={{ fontStyle: "italic" }}>{item.school.country}</span>
        </h4>

        <div>
          {item.school.i18n.faculty ? (
            <span>
              {this.titles.fac}: <i>{item.school.i18n.faculty}</i>
            </span>
          ) : null}
        </div>

        {item.school.i18n.major ? (
          <span>
            {this.titles.major}: <i>{item.school.i18n.major}</i>
          </span>
        ) : null}

        {item.school.remark ? (
          <span>
            {item.school.degree} {item.school.remark}
          </span>
        ) : null}
      </div>
    );

    let subjects = (
      <div className="blocked" style={{ columnCount: 2 }}>
        <h4>{this.titles.subjects}</h4>
        {this.getSubjects(item)}
      </div>
    );

    const title = item.school.obtained ? (
      <li style={{ fontWeight: "bold" }}>{item.school.degree}</li>
    ) : null;

    const subheader = (
      <span>
        <a
          href={`https://www.google.com/maps?q=${item.school.city}`}
          target="_blank"
        >
          {item.school.o}
        </a>{" "}
        - <span style={{ fontStyle: "italic" }}>{item.school.country}</span>
      </span>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }} aria-label="job">
        {Array.from(item.school.degree)[0]}
      </Avatar>
    );

    const cardcontent = (
      <div>
        {item.school.i18n.faculty ? (
          <span>
            {this.titles.fac}: <i>{item.school.i18n.faculty}</i>
          </span>
        ) : null}
      </div>
    );

    const cardactions = (
      <Typography variant="body1" color="text.secondary">
        {this.getSubjects(item)}
        {item.school.i18n.major ? (
          <span>
            {this.titles.major}: <i>{item.school.i18n.major}</i>
          </span>
        ) : null}

        {item.school.remark ? (
          <span>
            {item.school.degree} {item.school.remark}
          </span>
        ) : null}
      </Typography>
    );

    let c = getCard(title, subheader, avatar, cardcontent, cardactions);
    return c;
  }

  render() {
    return (
      <Card>
        <CardHeader
          // avatar={
          //   <Avatar
          //     alt="Edudation"
          //     src="./img/unused/id.png"
          //     sx={{ width: 48, height: 48 }}
          //   />
          // }
          title={
            <Typography gutterBottom variant="h5" component="div">
              Education
            </Typography>
          }
          subheader={`💼 ${this.titles.edu}`}
        />
        <CardContent>
          <Typography variant="h4" color="text.secondary">
            {this.titles.edu}
          </Typography>
          {this.props.data.map((item, index) => {
            if (item.school) return this.getEducation(item);
            if (item.certification)
              return this.getCertification(item.certification);
            return null;
          })}
        </CardContent>
      </Card>
    );
  }
}
