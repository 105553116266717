import LocalizedStrings from "react-localization";

export var data_edu = [
  {
    school: {
      ou: "Media Technologie",
      o: "Hogescool Utrecht",
      city: "Utrecht",
      country: "NL",
      obtained: true,
      degree: "Bachelor of Engineering",
      url:
        "https://www.studiekeuze.hu.nl/~/media/LLL/Docs/Studiegidsen/FNT/2007/TD-2007/Studiegids%20MT%2007-08.pdf",
      period: { from: new Date("2001-09-01"), to: new Date("2009-02-01") },
      i18n: new LocalizedStrings({
        nl: {
          faculty: "Electrotechniek",
          subjects: [
            "Software Ontwikkeling",
            "Digitale Signaalverwerking",
            "Netwerk",
            "Hardware Architectuur",
            "3D",
          ],
          major: "Physical User Interfaces",
        },
        en: {
          faculty: "Electrical Engineering",
          subjects: [
            "Software Development",
            "Digital Signal Processing",
            "Networking",
            "Hardware Architecture",
            "3D",
          ],
          major: "Physical User Interfaces",
        },
        fr: {
          faculty: "Électrotechnique",
          subjects: [
            "Développement logiciel",
            "Traitement du signal numérique",
            "Réseau",
            "Architecture Materiel",
            "3D",
          ],
          major: "Interface Utilisateur Physique",
        },
      }),
    },
  },
  {
    school: {
      ou: "IFSIC",
      o: "Rennes 1",
      city: "Rennes",
      country: "FR",
      url: "https://istic.univ-rennes1.fr/la-lettre-de-lifsic-1994-2008",
      obtained: false,
      degree: "Master",
      period: { from: new Date("2006-01-01"), to: new Date("2006-07-01") },
      degree: "Master",
      remark: "Erasmus exchange",
      i18n: new LocalizedStrings({
        nl: {
          faculty: "Computerwetenschappen",
          subjects: [
            "Géstion de mémoire",
            "Communication & Réseaux",
            "Cryptographie",
            "Methodology",
          ],
          major: null,
        },
        en: {
          faculty: "Computer Science",
          subjects: [
            "Memory management",
            "Communication & Networks",
            "Cryptography",
            "Methodology",
          ],
          major: null,
        },
        fr: {
          faculty: "Computer Science",
          subjects: [
            "Geheugenbeheer",
            "Communicatie & Netwerken",
            "Cryptografie",
            "Methodologie",
          ],
          major: null,
        },
      }),
    },
  },
  {
    certification: {
      o: "Microsoft",
      obtained: true,
      degree: "Azure Administrator Associate",
      period: { from: new Date("2019-10-24"), to: new Date("2021-10-24") },
      badgeUrl:
        "https://images.youracclaim.com/images/35d18649-95c6-4c78-b07a-cfc1362318f3/azure-administrator-associate.png",
    },
  },
];
