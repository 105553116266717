var langLevels = {
  en: {
    a1: {
      title: "Breakthrough or beginner",
      desc: [
        "Can understand and use familiar everyday expressions and very basic phrases aimed at the satisfaction of needs of a concrete type.",
        "Can introduce themselves and others and can ask and answer questions about personal details such as where he/she lives, people they know and things they have.",
        "Can interact in a simple way provided the other person talks slowly and clearly and is prepared to help.",
      ],
    },
    a2: {
      title: "Waystage or elementary",
      desc: [
        "Can understand sentences and frequently used expressions related to areas of most immediate relevance (e.g. very basic personal and family information, shopping, local geography, employment).",
        "Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters.",
        "Can describe in simple terms aspects of their background, immediate environment and matters in areas of immediate need.",
      ],
    },
    b1: {
      title: "Threshold or intermediate",
      desc: [
        "Can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc.",
        "Can deal with most situations likely to arise while travelling in an area where the language is spoken.",
        "Can produce simple connected text on topics that are familiar or of personal interest.",
        "Can describe experiences and events, dreams, hopes and ambitions and briefly give reasons and explanations for opinions and plans.",
      ],
    },
    b2: {
      title: "Vantage or upper intermediate",
      desc: [
        "Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in their field of specialization.",
        "Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party.",
        "Can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.",
      ],
    },
    c1: {
      title: "Effective operational proficiency or advanced",
      desc: [
        "Can understand a wide range of demanding, longer clauses, and recognize implicit meaning.",
        "Can express ideas fluently and spontaneously without much obvious searching for expressions.",
        "Can use language flexibly and effectively for social, academic and professional purposes.",
        "Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organizational patterns, connectors and cohesive devices.",
      ],
    },
    c2: {
      title: "Mastery or proficiency",
      desc: [
        "Can understand with ease virtually everything heard or read.",
        "Can summarize information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation.",
        "Can express themselves spontaneously, very fluently and precisely, differentiating finer shades of meaning even in the most complex situations.",
      ],
    },
  },
  fr: {
    a1: { title: "" },
    a2: { title: "" },
    b1: {
      title: "Bonnes notions",
      desc:
        "Ce niveau intermédiaire signifie que vous êtes capable de comprendre des points-clés dans une conversation, lorsque le langage est clair et standard. Vous vous débrouillez assez facilement.",
    },
    b2: {
      title: "Moyen",
      desc:
        "Ce niveau intermédiaire correspond à un niveau de connaissances linguistiques de base.",
    },
    c1: {
      title: "Courant",
      desc:
        "Ce niveau de langue indique que vos connaissances linguistiques dépassent les connaissances de base, et qu’il est possible pour vous de mener une conversation sans difficultés. Vous êtes à l’aise ; vous pouvez communiquer avec fluidité et aisance et vous êtes capable de vous exprimer avec facilité sans trop chercher vos mots.",
    },
    c2: {
      title: "Bilingue/Trilingue:",
      desc:
        "Ce niveau expérimenté indique qu’il s’agit de votre langue maternelle (natif) ou d’une ou plusieurs langues que vous maîtrisez extrêmement bien sur n’importe quel sujet. Vous êtes capable de comprendre sans effort tout ce que vous lisez ou ce que vous entendez et de vous exprimer de façon spontanée. Ce niveau correspond souvent à votre langue maternelle.",
    },
  },
};
module.exports = langLevels;
