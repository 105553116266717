export default [
  { title: "Skateboard", emoji: "0x1F6F9;" },
  { title: "jigsaw", emoji: "0x1F9E9;" },
  { title: "dna", emoji: "0x1F9EC;" },
  { title: "chemistry", emoji: "0x1F9EA;" },
  { title: "inf", emoji: "0x267E;" },
  { title: "pirate", emoji: "0x1F3F4;0x200D;0x2620;0xFE0F;" },
  { title: "camera", emoji: "0x1F4F7;" },
  { title: "guitar", emoji: "🎸" },
];
