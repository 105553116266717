import LocalizedStrings from "react-localization";

export var data_xp = [
  {
    public: true,
    type: "IT",
    company: {
      title: "VPTech",
      type: "Dev",
      typel: "Senior Software Engineer & SRE",
      dep: "Monitoring",
      city: "Full Remote",
      country: "FR",
      url: "https://careers.veepee.com/veepee-tech/",
      logo:
        "https://careers-web.apps.veepee.com/_next/image?url=%2Fimg%2Fbotlouc.png&w=256&q=75", //'https://careers-web.apps.veepee.com/img/veepeetech_logo_white.png'
    },
    contractType: "Full-Time",
    period: { from: new Date("2022-06-20"), to: new Date() },
    techno: [
      "Terraform, Ansible",
      "Docker, devcontainers",
      "Gitlab, Nomad",
      "Elastic, Prometheus, Grafana",
      "Go, Python, TypeScript, React, Angular",
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Dev & Ops Engineer",
        description: "DevOps Engineering Monitoring Platform",
        tasks: [
          "Develop and deploy new APIs and GUIs to interact with internal tools",
          "Help Sysops maintaining services in operational conditions",
          "Analyze and resolve performance and scalability anomalies (load tests) of current and historical deployments",
          "Participate in daily support duties",
          "Automating/Simplyfing procedures",
          "Ensure DevOps practices are followed and help the team growing on this topic",
        ],
      },
      fr: {
        jobtitle: "Dev & Ops Engineer",
        description: "Ingénierie plateforme de surveillance",
        tasks: [
          "Développer et déployer de nouvelles API et interfaces graphiques pour interagir avec les outils internes",
          "Aider les Sysops à maintenir les services en conditions opérationnelles",
          "Analyser et résoudre les anomalies de performance et d'évolutivité (tests de charge) des déploiements actuels et historiques",
          "Participer aux tâches de soutien quotidiennes",
          "Automatiser/Simplifier les procédures",
          "Assurez-vous que les pratiques DevOps sont suivies et aidez l'équipe à grandir sur ce sujet",
        ],
      },
      nl: {
        jobtitle: "Dev & Ops Engineer",
        description: "Site Reliability Engineering",
        tasks: [
          "Ontwikkel en implementeer nieuwe API's en GUI's om te communiceren met interne tools",
          "Help Sysops om services in operationele omstandigheden te houden",
          "Analyseren en oplossen van prestatie- en schaalbaarheidsanomalieën (loadtests) van huidige en historische implementaties",
          "Deelnemen aan dagelijkse ondersteunende taken",
          "Procedures automatiseren/vereenvoudigen",
          "Zorg ervoor dat DevOps-praktijken worden gevolgd en help het team groeien over dit onderwerp",
        ],
      },
    }),
    urls: [
      /*{
    title: 'SPaaS',
    url: 'https://console.paas.sigma.fr'
  },*/
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Orange Cloud for Business",
      type: "Cloud",
      typel: "Cloud and network services",
      dep: "Infogérance",
      city: "Rennes",
      country: "FR",
      url: "https://www.orange-business.com/",
      logo:
        "https://upload.wikimedia.org/wikipedia/commons/c/c8/Orange_logo.svg",
    },
    contractType: "Full-Time",
    period: { from: new Date("2020-03-02"), to: new Date("2022-05-08") },
    techno: [
      "OpenShift",
      "Kubernetes",
      "Docker",
      "Ansible",
      "ElasticSearch",
      "OpenDistro",
      "etcd",
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Container Engineer",
        description: "OpenShift Expert",
        tasks: [
          "Level 3 support",
          "Administration",
          "Documention",
          "Automating/Simplyfing procedures",
          "Troubleshooting",
          "Analysis",
        ],
      },
      fr: {
        jobtitle: "Ingénieur Conteneurisation",
        description: "Expert OpenShift",
        tasks: [
          "Support niveau 3",
          "Rédaction fiches d'exploitation",
          "Administration",
          "Fiabilisation",
          "Automatisation/Simplification de procédures",
        ],
      },
      nl: {
        jobtitle: "Container Engineer",
        description: "OpenShift Expert",
        tasks: [
          "Level 3 support",
          "Documentatie",
          "Administrator",
          "Stabilisering platform",
          "Automatisering",
        ],
      },
    }),
    urls: [
      /*{
    title: 'SPaaS',
    url: 'https://console.paas.sigma.fr'
  },*/
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Groupe Sigma",
      type: "Cloud",
      typel: "Cloud and network services",
      dep: "Infogérance",
      city: "Nantes",
      country: "FR",
      url: "https://www.sigma.fr",
      logo:
        "https://upload.wikimedia.org/wikipedia/fr/2/21/Logo_Groupe_Sigma.png",
    },
    contractType: "Full-Time",
    period: { from: new Date("2019-05-13"), to: new Date("2019-12-12") },
    techno: ["OpenShift", "Ansible", "Docker", "Kubernetes", "Azure"],
    local: new LocalizedStrings({
      en: {
        jobtitle: "DevOps Integrator",
        description: "Public and private cloud services",
        tasks: [
          "Custom Docker images for OpenShift",
          "Integration",
          "Monitoring",
          "Automating public cloud",
          "Software Forge",
        ],
      },
      fr: {
        jobtitle: "Intégrateur DevOps",
        description: "Service et applications orienté cloud publique et privé",
        tasks: [
          "Création image Docker pour OpenShift sur mesure",
          "Intégration",
          "Monitoring",
          "Automatisation cloud publique",
          "Forge logicielle",
        ],
      },
      nl: {
        jobtitle: "DevOps Integrator",
        description: "Privé en private cloud diensten",
        tasks: [
          "Op maat gemaakte Docker images voor OpenShift",
          "Integratie",
          "Monitoring",
          "Automatisering public cloud",
          "Software forge",
        ],
      },
    }),
    urls: [
      /*{
    title: 'SPaaS',
    url: 'https://console.paas.sigma.fr'
  },*/ {
        title: "Powershell generator for Azure template deployement",
        url: "https://github.com/jeanphilipperuijs/arm2ps1",
      },
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Télécom ParisTech",
      type: "Grande École",
      typel: "French public institutions of higher education and research ",
      dep: "Image, Données, Signal (IDS)",
      city: "Paris",
      country: "FR",
      url: "http://www.imt.fr",
      logo:
        "https://images.theconversation.com/institutions/2346/logos/logo-1559577082.png?ixlib=rb-1.1.0&q=45&auto=format&w=170&h=170",
    },
    contractType: "Full-Time",
    period: { from: new Date("2016-06-13"), to: new Date("2017-12-12") },
    techno: [
      "HbbTV",
      "JavaScript",
      "NodeJS",
      "ReactJS" /* 'DVB', 'GIT',*/,
      /*'REST', 'Composer'*/
      ,
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Research Scientist",
        description: "European open source project",
        tasks: [
          "MPAT.eu Horizon 2020",
          "Expert Test & Integration",
          "Teaching Software Engineer",
        ],
      },
      fr: {
        jobtitle: "Ingénieur de recherche / Chargés d’Etudes",
        description: "Projet européen logiciel libre",
        tasks: [
          "Encadrement étudiants en Génie Logiciel",
          "Expert Test & Intégration",
          "Développement et Architecture",
          "MPAT Projet HbbTV Horizon 2020",
        ],
      },
      nl: {
        jobtitle: "Onderzoekswetenschapper",
        description: "Europees open source project",
        tasks: [
          "Software engineering aan leerlingen",
          "Expert Test & Intégration",
          "Développement et Architecture",
          "MPAT Projet HbbTV Horizon 2020",
        ],
      },
    }),
    urls: [
      {
        title: "Télécom ParisTech - Groupe MultiMédia -",
        url: "http://www.tsi.telecom-paristech.fr/mm/themes/equipe-gpac/",
      },

      { title: "MPAT Project Home Page", url: "http://mpat.eu" },

      { title: "MPAT Project Github", url: "https://github.com/mpat-eu" },
      /* {
       title: 'MPAT Plugins', url:
         'https://github.com/jeanphilipperuijs'
     },*/
      {
        title: "GitLab Fraunhofer",
        url: "https://gitlab.fokus.fraunhofer.de/jp.ruijs",
      },
      {
        title: "MPAT Coders Meeting at ULANC",
        url: "https://twitter.com/MPAT_project/status/768897594395353089",
      } /*,
    { title: 'PACT', url: 'https://pact.wp.imt.fr/vitrine/projets-2016-2017/' }
*/,
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Freelance",
      type: "Data Visualization",
      city: "Zeist",
      country: "NL",
    },
    urls: [
      /*{
      title: 'GAS (Geographic Animation System) Video examples',
      url:
        'http://www.youtube.com/watch?v=fQB3FEfrSHo&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8'
    }*/
    ],
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        description:
          "Various projects including improving GIS Animation System",
        tasks: ["Data Visualization", "Dataset assessment"],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        description:
          "Divers projets parmis améliorer le système d'animation SIG",
        tasks: ["Visualisation de données", "Évaluation des données"],
      },
    }),
    period: { from: new Date("2015-01-01"), to: new Date("2016-05-01") },
    techno: ["Python", "Django", "MySQL", "D3JS", "Java"],
  },
  {
    public: false,
    type: "IT",
    company: {
      title: "DutchWorld Solutions",
      city: "Amersfoort",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: { jobtitle: "Software Architect", tasks: ["eCommerce"] },
      fr: { jobtitle: "Architect Logiciel", tasks: ["eCommerce"] },
    }),
    period: { from: new Date("2015-11-01"), to: new Date("2015-12-01") },
    techno: ["odoo", "Amazon Web Service", "Magento", "Docker"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Sensoterra",
      type: "Start-Up",
      city: "Amsterdam",
      country: "NL",
    },
    contractType: "Freelance",
    url: "http://www.sensoterra.com/",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Hardware Engineer",
        description: "Rugged prototype",
        tasks: [
          "Improved Beagle-based Prototype Assembly",
          "Shock resistance, high temperature, sand storm and tightness",
          "Verification of electrical components and their assembly",
          "Strengthening weak links.",
        ],
      },
      fr: {
        jobtitle: "Assembleur",
        tasks: [
          "Amélioration de l'assemblage de prototype basé sur Beagle",
          "Resistance au choque, haute temperature, tempête de sable et étanchité.",
          "Vérification des composants électrique et de leur montage.",
          "Renforcement des maillons faibles.",
        ],
      },
    }),
    period: { from: new Date("2015-10-01"), to: new Date("2015-10-31") },
    techno: ["BeagleBoard", "GSM", "3G"],
    urls: [
      { title: "Land Life Company", url: "http://www.landlifecompany.com/" },
      {
        title: "Demo video",
        url: "https://www.youtube.com/watch?v=yRtlE2S1wIY",
      },
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "IP-label",
      type: "Application Performance Monitoring",
      city: "La Garenne-Colombes",
      country: "FR",
      postalcode: "92250",
      logo:
        "https://pbs.twimg.com/profile_images/1115913168285515778/blfQ0CFW_400x400.png",
    },
    urls: [
      {
        title: "ip-label Wiki NEWTEST",
        url: "http://wikipl.ip-label.com/NEWTEST/Support/Windows/ServerCore",
      },
    ],
    contractType: ["Full-Time"],
    period: { from: new Date("2014-06-01"), to: new Date("2015-04-01") },
    techno: [
      "Newtest",
      "Windows Server Core",
      "DVB",
      "IEEE 802",
      "Digital Imaging",
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Support Engineer",
        description: "Problem-Solving",
        tasks: [
          "Analyze and research client's issues in a timely manner",
          "Follow up with the customer with recommendations and action plans.",
          "High confidentiality",
          "Verify source code to facilitate bug fixes",
        ],
      },
      nl: {
        jobtitle: "Support Engineer",
        description: "Dépannage",
        tasks: [
          "Analyse en onderzoek van probleem klant in zeer korte tijd",
          "Begeleiden en advieseren voor de beste oplossing",
          "Hoge vertrouwelijkheid",
          "Verify source code to facilitate bug fixes",
        ],
      },
      fr: {
        jobtitle: "Ingénieur Support",
        description: "Troubleshooting",
        tasks: [
          "Analyser et rechercher les problèmes des clients en temps opportun",
          "Suivi avec le client avec des recommandations et des plans d'action.",
          "Haute confidentialité",
          "Vérifier le code source pour faciliter la résolution des bogues",
        ],
      },
    }),
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Ministerie van Onderwijs, Cultuur en Wetenschap",
      city: "Den-Haag",
      country: "NL",
      type: "Dutch Ministry",
      logo:
        "https://upload.wikimedia.org/wikipedia/commons/b/b3/Logo_rijksoverheid.svg",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        description: "Interactive data visualisation",
        tasks: [
          "Dataset optimization",
          "Interactive bidirectional data visualization",
          "Hosting",
        ],
      },
      fr: {
        jobtitle: "Développeur Logiciel",
        description: "Visualisation interactive de données",
        tasks: [
          "Optimisation du jeu de données",
          "Visualisation interactive des données bidirectionnelle.",
          "Hébergement",
        ],
      },
      nl: {
        jobtitle: "Software Ontwikkelaar",
        description: "Interactive data visualisation",
        tasks: [
          "Dataset-optimalisatie",
          "Interactieve bidirectionele datavisualisatie",
          "Hosting",
        ],
      },
    }),
    period: { from: new Date("2014-02-01"), to: new Date("2014-08-01") },
    techno: ["D3JS", "Python"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "VPRO Nederland van Boven",
      type: "Dutch Public Television",
      city: "Hilversum",
      country: "NL",
      logo: "https://upload.wikimedia.org/wikipedia/commons/6/62/VPRO_logo.svg",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Architect",
        description: "Conception of the Geographical Animation System",
        tasks: [
          "Dataset conversion",
          "Conception animation framework",
          "Frame generation",
          "Video encoding",
          "Hosting",
        ],
      },
      fr: {
        jobtitle: "Architect",
        description: "Conception de système d'animation géographique",
        tasks: [
          "Conversion des jeux de données",
          "Conception et création framework d'animation",
          "Génération des trames",
          "Encodage vidéo",
          "Hebergement",
        ],
      },
      nl: {
        jobtitle: "Software Architect",
        description: "Creatie van Geographische Animatie Systeem",
        tasks: [
          "Dataset-conversie",
          "Conceptie-animatieframework",
          "Frame generatie",
          "Videocodering",
          "Hosting",
        ],
      },
    }),
    urls: [
      { title: "Credits", url: "./img/unused/20140110_231232_climax.png" },
      /*, {
      title: 'Dredging Maasvlakte 2',
      url:
        'https://www.youtube.com/watch?v=LxtzPWWHi60&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8&index=26'
    },

    {
      title: 'Building of the Netherlands since the medivals',
      url:
        'https://www.youtube.com/watch?v=10LoZrI4m4I&index=4&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8'
    }*/
    ],
    period: { from: new Date("2013-02-01"), to: new Date("2014-06-01") },
    techno: ["Java", "Python", "GIS", "NMEA", "WGS84", "RDS"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "CNRS DSI",
      type: "French National Center for Scientific Research",
      city: "Meudon",
      country: "FR",
      logo:
        "https://upload.wikimedia.org/wikipedia/fr/8/8e/Centre_national_de_la_recherche_scientifique.svg",
    },
    local: new LocalizedStrings({
      fr: {
        jobtitle: "Ingénieur d'études",
        description: "Quality Assurance, Research & Development",
        contractType: "CDD",
        tasks: [
          "Développement maquettes",
          "Analyse moniteur grilles de calcules",
          "Projet Pilot",
        ],
      },
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description: "Quality Assurance, Research & Development",
        tasks: [
          "Proof-of-Concept",
          "Reverse-Engineering",
          "Prototyping",
          "Pilot Experiment",
        ],
      },
      nl: {
        jobtitle: "Software Engineer",
        contractType: "Voltijd",
        description: "Quality Assurance, Research & Development",
        tasks: [
          "Proof-of-Concept",
          "Reverse-Engineering",
          "Prototyping",
          "Pilot Project",
        ],
      },
    }),
    period: { from: new Date("2010-11-01"), to: new Date("2012-11-01") },
    techno: [
      "C",
      "C#",
      "Python",
      "Java",
      "SharePoint" /*'OpenLDAP', 'MySQL'*/,
      /*  'Tomcat', 'JavaEE', */
      /*'JPA', 'Hibernate',*/
      /*'Play!', 'Groovy', 'Android',*/
      /*, 'Perl', 'perfSONAR', 'SharePoint',*/
    ],
    urls: [
      { title: "CoRe", url: "https://ods.cnrs.fr/portail_core.php" },
      { title: "CTA Observatory", url: "https://www.cta-observatory.org/" },
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Smile Open-Source Solutions",
      city: "Levallois-Perret",
      country: "FR",
      type: "Open-Source Solutions",
      url: "http://www.smile.eu",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description: "Web dev",
        tasks: ["WebService", "Multi-browser support"],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "CDI",
        description: "Web Dev",
        tasks: ["Développement services web"],
      },
      nl: {
        jobtitle: "Software Ontwikkelaar",
        contractType: "Voltijd",
        tasks: ["WebService", "Multi-browser support"],
      },
    }),
    period: { from: new Date("2010-09-01"), to: new Date("2010-10-01") },
    techno: ["JavaEE", "Spring", "Struts", "Hibernate", "HTML"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Buongiorno Spa.",
      type: "Mobile Services",
      city: "Paris",
      country: "FR",
      url: "https://www.buongiorno.com/",
    },
    urls: [
      {
        title: "Wikipedia",
        url: "https://en.wikipedia.org/wiki/Buongiorno_(company)",
      },
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description:
          "Developing and maintaining B2B mobile, web and SMS services",
        tasks: [
          "Client web-interface",
          "SMS services",
          "Proof-of-Concepts",
          "Administration services B2B.",
        ],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "CDI",
        description:
          "Développement et maintenance de services B2B mobiles, Web et SMS",
        tasks: [
          "Refonte site SFR",
          "Réalisation services SMS",
          "Proof-of-Concepts",
          "Administration services B2B.",
        ],
      },
      nl: {
        jobtitle: "Software Engineer",
        contractType: "Voltijd",
        description:
          "Ontwikkelen en onderhouden van B2B-mobiel, web- en sms-diensten",
        tasks: [
          "Client web-interface",
          "SMS diensten",
          "Proof-of-Concepts",
          "Administratie B2B diensten",
        ],
      },
    }),
    period: { from: new Date("2009-05-13"), to: new Date("2010-08-15") },
    techno: [
      "Java",
      "Linux",
      "Tomcat",
      "SMS",
      "MySQL",
      /*'OML','PML', 'WML','HTML',*/
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "DirectMovie",
      type: "Video-On-Demand",
      city: "Goirle/Hilversum",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        description: "Overlay ads for video on demand",
        contractType: "Graduation project",
        tasks: [
          "Prototyping",
          "Technical feasability and alternativity",
          "Video-on-Demand overlay Proof-of-Concepts",
        ],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "Projet de fin d'études",
        description: "Superposition d'annonces pour la vidéo à la demande",
        tasks: [
          "Prototypage",
          "Analyses d'options alternatives",
          "Maquette publicité Video-à-la-Demande",
        ],
      },
      nl: {
        jobtitle: "Ontwikkelingsingenieur",
        contractType: "Afstudeerproject",
        description: "Overlay-advertenties voor video on demand",
        tasks: ["Prototyping", "Onderzoek", "Proof of Concept VoD met overlay"],
      },
    }),
    period: { from: new Date("2007-07-01"), to: new Date("2008-02-15") },
    techno: [
      "C#",
      "SilverLight 1.1 alpha" /*, 'Windows Media Server', 'Visual Studio',
       'Windows Server', 'DRM'*/,
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Figi",
      url: "http://www.figi.com",
      type: "Theater",
      city: "Zeist",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Freelance",
        description: "Image analysis, indexing, search engine",
        tasks: [
          /*'Delight', 'Develop','Deliver', 'Depend',*/ "Back- & frontend",
          "Image analyses",
          "Search engine conception",
        ],
      },
      fr: {
        jobtitle: "Ingénieur de système",
        contractType: "Indépendant",
        description: "Analyse d'images, indexation, moteur de recherche",
        tasks: [
          "Conception back- et frond-end",
          "Analyse d'images",
          "Moteur de recherche",
        ],
      },
      nl: {
        jobtitle: "Systeemingenieur",
        contractType: "Zelfstandig",
        description: "Beeldanalyse, indexering, zoekmachine",
        tasks: ["Back- & frontend", "Beeld analyse", "Zoek machine"],
      },
    }),
    period: { from: new Date("2006-06-01"), to: new Date("2009-02-01") },
    techno: [
      "Java" /* 'Java Advanced Imaging',*/,
      /*'Digital Imaging Processing',*/
      "MySQL",
      "PHP",
      "Python",
      "JavaScript",
      "Django",
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "RIAGG Zwolle",
      url: "https://www.dimence.nl/locaties/burgemeester-roelenweg",
      type: "Mental Health Service",
      city: "Zwolle",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "System- & Network Administrator",
        contractType: "Consultant",
        description: "Secure & restore network and worstation back-ups",
        tasks: ["System management", "Network management"],
      },
      fr: {
        jobtitle: "Administrateur système et réseau",
        contractType: "Consultant",
        description: "Administration système et réseau",
        tasks: ["Gestion de système", "Gestion de réseau"],
      },
      nl: {
        jobtitle: "Systeem- en netwerkbeheerder",
        contractType: "Consultant",
        description: "Systeem- en netwerkadministratie",
        tasks: ["Systeembeheer", "Netwerkbeheer"],
      },
    }),
    period: { from: new Date("2005-02"), to: new Date("2005-12") },
    techno: ["VoIP", "Novell", "PXE", "Norton Ghost"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Reinaerde",
      url: "https://www.reinaerde.nl/",
      type: "Mental Health Service",
      city: "Utrecht",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Consultant",
        tasks: ["System management", "Network management"],
      },
      fr: {
        jobtitle: "Consultant",
        tasks: ["Gestion de système", "Gestion de réseau"],
      },
      nl: { jobtitle: "Consultant", tasks: ["Systeembeheer", "Netwerkbeheer"] },
    }),
    period: { from: new Date("2005-10"), to: new Date("2005-11") },
    techno: ["Microsoft", "Novell", "PXE"],
  },
  {
    public: true,
    type: "Photo",
    company: {
      title: "Hogeschool Utrecht",
      url: "http://www.hu.nl",
      type: "Education",
      city: "Utrecht",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["Preparing photos for new site"],
      },
      fr: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["Preparing photos for new site"],
      },
      nl: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["photos for new site"],
      },
    }),
    period: { from: new Date("2005-08"), to: new Date("2005-08") },
    techno: ["Photoshop"],
  },
  {
    public: true,
    type: "summerjob",
    company: {
      title: "Brittany Ferries",
      type: "Passenger Ferries",
      city: "Roscoff",
      country: "F",
    },
    contractType: "Temp",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Handlin",
        contractType: "Summer job",
        tasks: ["Pork derived production"],
      },
      fr: {
        contractType: "Travail d'été",
        jobtitle: "Agent de néttoyage",
        tasks: ["Préparation des cabines"],
      },
      nl: {
        jobtitle: "Behandeling",
        contractType: "Zomerbaan",
        tasks: ["Van varkensvlees afkomstige productie"],
      },
    }),
    period: { from: new Date("2005-07"), to: new Date("2005-08") },
    techno: ["none"],
  },
  {
    public: true,
    type: "usine",
    company: {
      title: "Jean Caby",
      type: "Salaison",
      city: "Lampaul-Guimiliau",
      country: "F",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Handling",
        contractType: "Summer job",
        tasks: ["Pork derived production"],
      },
      fr: {
        jobtitle: "Manutention",
        contractType: "Travail d'été",
        tasks: ["Production dérivé de porc"],
      },
      nl: {
        jobtitle: "Behandeling",
        contractType: "Zomerbaan",
        tasks: ["Van varkensvlees afkomstige productie"],
      },
    }),
    period: { from: new Date("2005-07"), to: new Date("2005-08") },
    techno: ["none"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Eneco",
      url: "http://www.ogd.nl",
      type: "Consultancy",
      city: "Utrecht",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "System administrator",
        contractType: "Consultant",
        tasks: ["System management", "Network management"],
      },
      fr: {
        jobtitle: "Administrateur système",
        contractType: "Consultant",
        tasks: ["Gestion de système", "Gestion de réseau"],
      },
      nl: {
        jobtitle: "Systeembeheerder",
        contractType: "Consultant",
        tasks: ["Systeembeheer", "Netwerkbeheer"],
      },
    }),
    period: { from: new Date("2005-06"), to: new Date("2005-06") },
    techno: ["VoIP"],
  },

  {
    public: true,
    type: "media",
    company: {
      title: "Algemene Media Groep",
      type: "Advertising",
      city: "Rotterdam",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Order processing",
        contractType: "Summer job",
        tasks: ["Bulk Data Entry", "Advertisements"],
      },
      fr: {
        jobtitle: "Traitement des commandes",
        contractType: "Saisonnier",
        tasks: ["Saisi de données en masse", "Publicités"],
      },
      nl: {
        jobtitle: "Orderverwerking",
        contractType: "Zomerbaan",
        tasks: ["Bulk gegevensinvoer", "Advertenties"],
      },
    }),
    period: { from: new Date("2004-07"), to: new Date("2004-08") },
    techno: ["Windows", "Terminal", "Fax", "Print"],
  },

  {
    public: true,
    type: "IT",
    company: {
      title: "The BOX Holland BV",
      type: "Music Video On Demand",
      city: "Hilversum",
      country: "NL",
      url: "http://www.thebox.nl",
    },
    period: { from: new Date("2003-08-01"), to: new Date("2004-06-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster",
        description: "Migrating new website",
        contractType: "Intern",
        tasks: ["Solaris installation", "Website CMS intergration"],
      },
      fr: {
        jobtitle: "Webmaster",
        contractType: "Stage",
        tasks: ["Conseil interface télévision"],
      },
      nl: {
        jobtitle: "Web Meester",
        contractType: "Stage",
        tasks: ["Installatie", "Ontwikkeling", "Onderhoud"],
      },
    }),
    techno: ["Solaris", "JSP", "MySQL", "PHP"],
    /*  urls: [
      {title: '90s promo', url:
    'https://www.youtube.com/watch?v=SCf4203RnLM'},
      {
        title: 'Holiday Rap',
        url: 'https://www.youtube.com/watch?v=BQsQ0--doj4'
      }
    ]*/
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Figi",
      type: "Conference Centre",
      url: "http://www.figi.nl",
      city: "Zeist",
      country: "NL",
    },
    period: { from: new Date("2000-02-01"), to: new Date("2001-09-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Administrator",
        contractType: "Full-Time",
        description: "Administrating network, systems & workstations",
        tasks: ["Migration", "Implementation", "Maintenance"],
      },
      fr: {
        jobtitle: "Administrateur",
        contractType: "CDD",
        description:
          "Administration du réseau, des systèmes et des postes de travail",
        tasks: ["Migration", "Implementation", "Maintenance"],
      },
      nl: {
        jobtitle: "Beheerder",
        contractType: "Voltijd",
        description: "Beheer van netwerk, systemen & werkstations",
        tasks: ["Migratie", "Implementatie", "Onderhoud"],
      },
    }),
    techno: ["Novell NetWare", "SCO Unix", "Exchange"],
  },
  {
    public: false,
    type: "IT",
    company: {
      title: "BuitenDeDeur",
      type: "Start-Up",
      city: "Utrecht",
      url: "http://www.buitendedeur.nl",
      country: "NL",
    },
    period: { from: new Date("1999-01-01"), to: new Date("2000-01-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster",
        contractType: "Start-Up",
        description: "Launch start-up web site",
        tasks: ["Web development", "DB concept", "GUI implementation"],
      },
      nl: {
        jobtitle: "Webmaster",
        description: "Lancering website",
        tasks: ["Web development", "DB concept", "Interface implementatie"],
      },
      fr: {
        jobtitle: "Webmaster",
        description: "Lancement site web",
        tasks: [
          "Développement site web",
          "Conception base de données",
          "Implementation charte graphique",
        ],
      },
    }),
    techno: ["PHP", "MySQL"],
  },
  {
    public: false,
    type: "IT",
    company: {
      title: "Social Werkvoorziening Zeist",
      url: "http://www.swzeist.nl",
      type: "Social Work",
      city: "Zeist",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster",
        description: "Launch web site",
        tasks: [
          "Initial launch website",
          "Developement",
          "Hosting",
          "Maintenance",
        ],
      },
      fr: {
        jobtitle: "Webmaster",
        description: "Lancement site web",
        tasks: ["Implementation charte graphique", "Maintenance du contenu"],
      },
      nl: {
        jobtitle: "Webmaster",
        description: "Lancering website",
        tasks: ["Lancering website", "Ontwikkeling", "Hosting", "Onderhoud"],
      },
    }),
    period: { from: new Date("1999-01-01"), to: new Date("2001-01-01") },
    techno: ["PHP", "MySQL"],
  },
  {
    public: true,
    type: "horeca",
    company: {
      title: "Golden Tulip Barbizon",
      type: "Hotel",
      city: "Maastricht",
      country: "NL",
    },
    contractType: "Internship",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Duty Manager",
        description: "Keep the hotel well oiled and running",
        tasks: ["Room Service", "Stewarding", "Banqueting", "Restaurant"],
      },
      fr: {
        jobtitle: "Gestionnaire de service",
        description: "All-Round",
        tasks: ["Service de chambre", "Stewarding", "Banquet", "Restaurant"],
      },
      nl: {
        jobtitle: "Duty Manager",
        description: "All-Round",
        tasks: ["Room Service", "Stewarding", "Banqueting", "Restaurant"],
      },
    }),
    period: { from: new Date("1998-08"), to: new Date("1999-02") },
    techno: ["Social Engineering", "Novell", "Excel", "Pegasus Mail"],
  },
];
