export function antispammail(email) {
  return email
    .split(".")
    .join(" [dot] ")
    .replace("-", " [dash] ")
    .replace("@", " [at] ");
  //.replace(".", " [dot] ");
}
export function hmmz() {
  /*    let ck = getCookie(this.tempkey);
      this.setState({
          rui: ck},console.log(this.state))*/
}
export function keygen(sz = 8) {
  let v = [];
  let a = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < sz; i++)
    v.push(a.charAt(Math.floor(Math.random() * a.length)));
  return v.join("");
}

export const host = process.env.REACT_APP_HOST || "https://cv.ruijs.fr";
