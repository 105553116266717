export var emojis = {
  flags: {
    en: "0x1F1EC;0x200D;0x1F1E7;",
    nl: "0x1F1F3;0x200D;0x1F1F1;",
    fr: "0x1F1EB;0x200D;0x1F1F7;",
    es: "0x1F1EA;0x200D;0x1F1F8;",
  },
  affinities: {
    microbe: "0x1F9A0;",
    skateboard: "0x1F6F9;",
    jigsaw: "0x1F9E9;",
    dna: "0x1F9EC;",
    chemistry: "0x1F9EA;",
    inf: "0x267E;",
    pirate: "0x1F3F4;0x200D;0x2620;0xFE0F;",
    camera: "0x1F4F7;",
    guitar: "🎸",
    dude: "👨‍💼",
    school: "🏫",
  },
};
