import { keygen } from "../helpers";

export function setCookie(cname, cvalue, exdays = 1 / 96) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires}";path=/`;
}

export function getCookie(cname) {
  let name = `${cname}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function delCookie(cname) {
  // document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

export function gen(cname) {
  setCookie(cname, keygen(3), 0.41666);
  return getCookie(cname);
}

export function magic(rui = "rui") {
  // import {getCookie,gen,delCookie} from './koekie/monster.js';
  let tc = getCookie(rui);
  if (tc) {
    console.log(`[${rui}] == ${tc}`);
  } else {
    console.log(`[${rui}] <= ${gen(rui)}`);
  }
  delCookie("DKnb");
}

export function vape() {
  document.cookie.split("; ").map((d) => {
    let k = d.split("=")[0];
    console.log(`vaping [${k}]`);
    delCookie(k);
  });
}
