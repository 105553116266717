export function getYearMonth(d) {
  return `${d.getYear() + 1900}/${d.getMonth() + 1}`;
}

export function getYear(d) {
  return d.getYear() + 1900;
}

export function getMonths(from, to) {
  return to.getMonth() - from.getMonth();
}

export function getYears(from, to) {
  return to.getYear() - from.getYear() + 100;
}

export function getPeriod(
  from,
  to = new Date(),
  titles = {
    year: "year",
    and: "&",
    years: "years",
    month: "month",
    months: "months",
  }
) {
  //  console.log('getPeriod', from, to);
  if (from === undefined) from = new Date();
  // console.log(titles);
  let yearFrom = getYear(from);
  let yearTo = getYear(to);
  let ymFrom = getYearMonth(from);
  let ymTo = getYearMonth(to);
  let yt = getYears(from, to) - 100;
  let mt = getMonths(from, to);
  // console.log('yt',yt);
  let unitYear = yt >= 2 ? titles.years : titles.year;
  let unitMonth = mt > 1 ? titles.months : titles.month;
  if (yt > 0) {
    let yrPer = yearTo - yearFrom;
    // console.log(yt);
    if (mt > 0) {
      return [
        `${yearFrom} - ${yearTo}`,
        `${yrPer} ${unitYear} ${titles.and} ${mt} ${unitMonth}`,
      ];
      // return `${yearFrom} - ${yearTo} (${yrPer} ${unitYear} ${titles.and} ${mt} ${unitMonth})`;
    }
    return [`${yearFrom} - ${yearTo}`, `${yrPer} ${unitYear}`];
    //  return `${yearFrom} - ${yearTo} (${yrPer} ${unitYear})`;
  } else {
    if (mt >= 4 && mt < 12) {
      //  return `${ymFrom} - ${ymTo} (${mt} ${unitMonth})`;
      return [`${ymFrom} - ${ymTo}`, `${mt} ${unitMonth}`];
    }
    if (mt >= 12 && mt < 24) {
      //   return `${ymFrom} - ${ymTo} (${mt} ${unitMonth})`;
      return [`${ymFrom} - ${ymTo}`, `${mt} ${unitMonth}`];
    }
    return [`${yearFrom}`, `${titles.months}`];
  }
}

/*
 getPeriod(item) {
    const yearFrom = this.getYear(item.period.from);
    const yearTo = this.getYear(item.period.to);
    const ymFrom = this.getYearMonth(item.period.from);
    const ymTo = this.getYearMonth(item.period.to);
    // return `${ys} - ${ye}`;
    let yt = yearTo - yearFrom;
    let mt = item.period.to.getMonth() - item.period.from.getMonth();
    let unitYear = yt > 1 ? this.titles.years : this.titles.year;
    let unitMonth = mt > 1 ? this.titles.months : this.titles.month;
    if (yt > 0) {
      if (mt > 0) {
        return `${yearFrom} - ${yearTo} (${yearTo - yearFrom} ${unitYear} and
 ${mt} ${unitMonth})`;
      }
      return `${yearFrom} - ${yearTo} (${yearTo - yearFrom} ${unitYear})`;
    } else {
      if (mt > 3 && mt < 12) return `${ymFrom} - ${ymTo} (${mt} ${unitMonth})`;

      if (mt >= 12 && mt < 24) return `${ymFrom} - ${ymTo} (${mt}
 ${unitMonth})`;

      return `${yearFrom} (3 ${this.titles.months})`;
    }
  }*/
